const replaceChar = (input) => {
  const inString = input.value
  //const outString = inString.toLowerCase().replace(/[^a-z0-9\-_]/gi,'')
  const outString = inString.replace(/[`~@#%^&*|+\=?;:'".<>\{\}\[\]\\\/]/gi, '').toLowerCase();
  input.value = outString
}

const urlInputValidation = () => {
    /*const urlInput = document.querySelector("#article_url")

    if(urlInput){

      urlInput.addEventListener("change", () => {
        replaceChar(urlInput)
      })
      urlInput.addEventListener("keyup", () => {
        replaceChar(urlInput)
      })
    }*/
}

window.allegro = window.allegro || {}
window.allegro.urlInputValidation = urlInputValidation

export { urlInputValidation }
